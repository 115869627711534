module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sumo/gatsby-browser.js'),
      options: {"plugins":[],"sumoSiteId":"75f9cef914acf03b2e18dd20ff7a5e4c84cea2ad7037ce254ac9b738568ce10b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    }]
